<template>
  <nav class="m-2 m-md-3" :style="{ color: iconColor }">
    <ul class="navbar-nav d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center w-100">
        <li class="nav-item" v-tooltip="'Accueil'">
          <router-link to="/" class="navbar-brand cursor-pointer d-flex align-items-center" tag="div">
            <BaseIcon class="d-flex" name="home" width="22" height="22" />
            <span class="d-none d-md-block ml-1" :style="{ color: iconColor }">QWARK</span>
          </router-link>
        </li>
        <div class="d-md-none ml-auto">
          <div v-if="mobileShowMenu" @click="mobileShowMenu = false">
            <BaseIcon name="x" width="22" height="22" />
          </div>
          <div v-if="!mobileShowMenu" @click="mobileShowMenu = true">
            <BaseIcon name="menu" width="22" height="22" />
          </div>
        </div>
      </div>
      <div class="ml-md-auto d-flex flex-column flex-md-row" v-if="mobileShowMenu || w > 768">
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && havePermission('manage_alertes')" v-tooltip="'Alertes'">
          <router-link to="/alertes" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="alert-triangle" width="22" height="22" />
              <span class="ml-2 d-md-none">Alertes</span>
            </div>
          </router-link>
        </li>

        <li
          class="nav-item mb-3 mb-md-0"
          v-if="isConnected && havePermission('manage_vehicules')"
          v-tooltip="'Véhicules'"
        >
          <router-link to="/vehicules" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="truck" width="22" height="22" />
              <span class="ml-2 d-md-none">Véhicules</span>
            </div>
          </router-link>
        </li>
        <li
          class="nav-item mb-3 mb-md-0"
          v-if="isConnected && havePermission('manage_candidats')"
          v-tooltip="'Candidats'"
        >
          <router-link to="/candidats" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="user-plus" width="22" height="22" />
              <span class="ml-2 d-md-none">Candidats</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && haveRole('admin')" v-tooltip="'Admin'">
          <router-link to="/admin" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="key" width="22" height="22" />
              <span class="ml-2 d-md-none">Admin</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && havePermission('manage_pcr')" v-tooltip="'PCR'">
          <router-link
            to="/radioprotection/dashboard"
            class="navbar-brand cursor-pointer"
            :active-class="activeClass"
            tag="div"
          >
            <div class="d-flex">
              <BaseIcon name="shield" width="22" height="22" />
              <span class="ml-2 d-md-none">PCR</span>
            </div>
          </router-link>
        </li>
        <li
          class="nav-item mb-3 mb-md-0"
          v-if="isConnected && havePermission('manage_materiels')"
          v-tooltip="'Matériel'"
        >
          <router-link to="/materiel" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="box" width="22" height="22" />
              <span class="ml-2 d-md-none">Matériel</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && havePermission('manage_qsser')" v-tooltip="'Qsser'">
          <router-link to="/qsser" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="award" width="22" height="22" />
              <span class="ml-2 d-md-none">QSSER</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && havePermission('manage_clients')" v-tooltip="'Clients'">
          <router-link to="/clients" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="book" width="22" height="22" />
              <span class="ml-2 d-md-none">Clients</span>
            </div>
          </router-link>
        </li>
        <li
          class="nav-item mb-3 mb-md-0"
          v-if="isConnected && havePermission('manage_affaires')"
          v-tooltip="'Affaires'"
        >
          <router-link to="/crm" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="briefcase" width="22" height="22" />
              <span class="ml-2 d-md-none">CRM</span>
            </div>
          </router-link>
        </li>
        <li
          class="nav-item mb-3 mb-md-0"
          v-if="isConnected && havePermission('manage_documents')"
          v-tooltip.bottom-start="'Documents'"
        >
          <router-link to="/documents" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="file" width="22" height="22" />
              <span class="ml-2 d-md-none">Documents</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item mb-3 mb-md-0" v-if="isConnected && havePermission('manage_sirh')" v-tooltip="'SIRH'">
          <router-link to="/sirh" class="navbar-brand cursor-pointer" :active-class="activeClass" tag="div">
            <div class="d-flex">
              <BaseIcon name="users" width="22" height="22" />
              <span class="ml-2 d-md-none">SIRH</span>
            </div>
          </router-link>
        </li>
        <div class="d-flex mb-3 mb-md-0">
          <li class="nav-item" v-if="darkMode" v-tooltip="'Mode clair'">
            <a to="/settings" class="navbar-brand cursor-pointer" @click.prevent="setLightMode">
              <BaseIcon name="sun" width="22" height="22" />
            </a>
          </li>
          <li class="nav-item" v-if="!darkMode" v-tooltip="'Mode sombre'">
            <a class="navbar-brand cursor-pointer" @click.prevent="setDarkMode">
              <BaseIcon name="moon" class width="22" height="22" pointer />
            </a>
          </li>
          <li class="nav-item" v-if="isConnected" v-tooltip="userName">
            <router-link to="/ma-page" class="cursor-pointer" :active-class="activeClass" tag="div">
              <AppAvatar
                class="d-flex align-items-center ml-1 mr-1"
                :url="avatar"
                propSize="2rem"
                v-if="isConnected"
              ></AppAvatar>
            </router-link>
          </li>
          <li class="nav-item mr-0 mr-md-3" v-if="isConnected" v-tooltip="'Déconnexion'">
            <div @click.prevent="logout" class="navbar-brand cursor-pointer mr-0" href="#">
              <BaseIcon name="log-out" width="22" height="22" />
            </div>
          </li>
        </div>
      </div>
    </ul>
  </nav>
</template>
<script>
import screenWidth from "@/mixins/screenWidth";
import BaseIcon from "@/components/bases/Icon.vue";
import AppAvatar from "@/components/bases/Avatar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  components: { BaseIcon, AppAvatar },
  mixins: [screenWidth],
  data() {
    return {
      mobileShowMenu: false,
    };
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
    ...mapGetters({ haveRole: "user/haveRole" }),

    isConnected() {
      return this.$store.getters["user/connected"];
    },
    darkMode() {
      return this.$store.getters["colors/darkMode"];
    },
    iconColor() {
      return this.$store.getters["colors/colors"].colorIconNavBar;
    },
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
    avatar() {
      return this.$store.getters["user/avatar"];
    },
    userName() {
      return this.$store.getters["user/data"].name;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("user/logout");
      this.$router.push({ name: "Accueil" });
    },
    setLightMode: function () {
      this.$store.dispatch("colors/setLightMode");
    },
    setDarkMode: function () {
      this.$store.dispatch("colors/setDarkMode");
    },
  },
};
</script>

<style scoped lang="css">
.router-link-exact-active svg {
  stroke: #007bff;
}

.router-link-active svg {
  stroke: #007bff;
}

.stroke-red {
  stroke: #dc3545;
}

.stroke-grey {
  stroke: #d3d3d3;
}
.navbar-brand {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
</style>
