<template>
  <div id="main" :class="appClass" :style="{ backgroundColor: color }">
    <slot></slot>
    <div id="notifications"></div>
  </div>
</template>
<script>
export default {
  name: "AppScreen",
  components: {},
  methods: {
    test: function() {}
  },
  computed: {
    color: function() {
      return this.$store.getters["colors/colors"].color1;
    },

    appClass: function() {
      // LIGHT MODE
      let out = "";
      if (this.$route.path === "/" || this.$route.path === "/login") {
        out = "bg-light-home";
      }

      // DARK MODE
      if (this.$store.getters["colors/darkMode"]) {
        if (this.$route.path === "/" || this.$route.path === "/login") {
          out = "bg-dark-home";
        }
      }

      return out;
    }
  },
  mounted() {}
};
</script>
<style>
@import "~vue-snotify/styles/material.css";
</style>
