import api from "@/services/api/qsser.js";
import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  data: {},
  ncs: [],
  evenements: [],
  animations: [],
  actions: [],
  participants: [],
  visites: [],
};

// getters
const getters = {
  data: function (state) {
    return state.data;
  },
  getField,
};

// actions
const actions = {
  getQsser({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then((response) => {
          commit("setQsser", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNcs({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getNcs()
        .then((response) => {
          commit("setNcs", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOldNcs({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOldNcs()
        .then((response) => {
          commit("setNcs", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getVisites({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getVisites()
        .then((response) => {
          commit("setVisites", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOldVisites({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOldVisites()
        .then((response) => {
          commit("setVisites", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEvenements({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getEvenements()
        .then((response) => {
          commit("setEvenements", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOldEvenements({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOldEvenements()
        .then((response) => {
          commit("setEvenements", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAnimations({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAnimations()
        .then((response) => {
          commit("setAnimations", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOldAnimations({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOldAnimations()
        .then((response) => {
          commit("setAnimations", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getActions({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getActions()
        .then((response) => {
          commit("setActions", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOldActions({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getOldActions()
        .then((response) => {
          commit("setActions", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then((response) => {
          commit("setData", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getParticipants({ commit }, $data) {
    return new Promise((resolve, reject) => {
      api
        .getParticipants($data)
        .then((response) => {
          commit("setParticipants", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNcs({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      api
        .createNcs(client_id)
        .then((response) => {
          commit("createNcs", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNcs({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateNcs(data)
        .then(() => {
          commit("updateNcs", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNcs({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteNcs(data.id)
        .then(() => {
          commit("deleteNcs", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createVisites({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      api
        .createVisites(client_id)
        .then((response) => {
          commit("createVistes", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateVisites({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateVisites(data)
        .then(() => {
          commit("updateVisites", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteVisites({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteVisites(data.id)
        .then(() => {
          commit("deleteVisites", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEvenements({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      api
        .createEvenements(client_id)
        .then((response) => {
          commit("createEvenements", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEvenements({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateEvenements(data)
        .then(() => {
          commit("updateEvenements");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEvenements({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteEvenements(data.id)
        .then(() => {
          commit("deleteEvenements", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createAnimations({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      api
        .createAnimations(client_id)
        .then((response) => {
          commit("createAnimations", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAnimations({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateAnimations(data)
        .then(() => {
          commit("updateAnimations");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAnimations({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteAnimations(data.id)
        .then(() => {
          commit("deleteAnimations", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createParticipant({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createParticipant(data)
        .then((response) => {
          commit("createParticipant", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteParticipant({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteParticipant(data.id)
        .then(() => {
          commit("deleteParticipant", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createActions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createActions(data)
        .then((response) => {
          commit("createActions", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateActions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateActions(data)
        .then((response) => {
          commit("updateActions", response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteActions({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteActions(data.id)
        .then(() => {
          commit("deleteActions", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setQsser(state, data) {
    //state.evenements = data.evenements;
    //state.animations = data.animations;
    state.actions = data.actions;
  },

  setNcs(state, data) {
    state.ncs = data;
  },

  setVisites(state, data) {
    state.visites = data;
  },

  setEvenements(state, data) {
    state.evenements = data;
  },

  setAnimations(state, data) {
    state.animations = data;
  },

  setActions(state, data) {
    state.actions = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setData(state, data) {
    state.data = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setParticipants(state, data) {
    state.participants = [];
    state.participants = data;
  },

  updateField,

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createNcs(state, data) {
    state.ncs.push({
      id: data.id,
      nc_reference: null,
      nc_description: null,
      nc_identifiant: null,
      nc_libelle: null,
      nc_date: data.nc_date,
      nc_type_id: null,
      nc_origine_id: null,
      nc_affaire_id: null,
      nc_user_id: data.nc_user_id,
      nc_cause_id: null,
      nc_societe_id: null,
      nc_faits: null,
      nc_causes: null,
      nc_derogation: false,
      nc_derogation_description: null,
      nc_derogation_justification: null,
      nc_cloture: false,
      nc_new: true,
    });
  },

  updateNcs() {},

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteNcs(state, data) {
    const index = state.ncs.findIndex((x) => x.id === data.id);
    state.ncs.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createVisites(state, data) {
    state.visites.push({
      id: data.id,
      visite_date: data.visite_date,
      visite_affaire_id: data.visite_affaire_id,
      visite_site_id: data.visite_site_id,
      visite_pilote_id: data.visite_pilote_id,
      visite_societe_id: data.visite_societe_id,
      visite_synthese: data.visite_synthese,
      visite_new: true,
    });
  },

  updateVisites() {},

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteVisites(state, data) {
    const index = state.visites.findIndex((x) => x.id === data.id);
    state.visite.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createEvenements(state, data) {
    state.evenements.push({
      id: data.id,
      evenement_date: data.evenement_date,
      evenement_site_client_id: null,
      evenement_pilote_id: data.evenement_pilote_id,
      evenement_societe_id: null,
      evenement_type_id: null,
      evenement_risque_id: null,
      evenement_classement_id: null,
      evenement_remarques: null,
      evenement_new: true,
    });
  },

  updateEvenements() {
    //
  },
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteEvenements(state, data) {
    const index = state.evenements.findIndex((x) => x.id === data.id);
    state.evenements.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createAnimations(state, data) {
    state.animations.push({
      id: data.id,
      anim_societe_id: null,
      anim_pilote_id: data.anim_pilote_id,
      anim_theme_id: null,
      anim_type_id: null,
      anim_date: data.anim_date,
      anim_commentaire: null,
      anim_new: true,
    });
  },

  updateAnimations() {
    //
  },
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteAnimations(state, data) {
    const index = state.animations.findIndex((x) => x.id === data.id);
    state.animations.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createParticipant(state, data) {
    state.participants.push({
      id: data.id,
      collaborateur_id: data.collaborateur_id,
      animation_id: data.animation_id,
      system_insert_time: data.system_insert_time,
      system_update_time: data.system_update_time,
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteParticipant(state, data) {
    const index = state.participants.findIndex((x) => x.id === data.id);
    state.participants.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createActions(state, data) {
    state.actions.push({
      id: data.id,
      description: null,
      action_origine_system_id: data.action_origine_system_id,
      action_origine_id: data.action_origine_id,
      action_etat_id: null,
      action_type_id: null,
      action_origineact_id: null,
      action_pilote_id: null,
      action_priorite_id: null,
      action_debut: data.action_debut,
      action_fin: data.action_fin,
      action_delais: null,
      action_details: null,
      action_verif_critere: null,
      action_verif_etat_id: null,
      action_verif_commentaire: null,
      action_new: data.action_new,
    });
  },

  updateActions(state, data) {
    const index = state.actions.findIndex((x) => x.id === data.id);
    state.actions[index].action_new = data.action_new;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteActions(state, data) {
    const index = state.actions.findIndex((x) => x.id === data.id);
    state.actions.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
