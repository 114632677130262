<template>
  <div class>
    <div v-if="!loading">
      <div
        class="avatar p-1"
        :class="{ 'rounded-circle': rounded }"
        v-bind:style="{
          backgroundImage: 'url(https://api.arkadia-qwark.fr/storage/' + img + ')',
          width: propSize,
          height: propSize,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppAvatarManager",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  props: {
    url: {
      type: String,
    },
    propSize: {
      type: String,
      default: "10rem",
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    img: function () {
      if (this.url === null) return "default.svg";
      if (this.url === "") return "default.svg";
      if (this.url === "null") return "default.svg";
      if (this.url === undefined) return "default.svg";
      if (this.url === "undefined") return "default.svg";
      return this.url;
    },
  },
};
</script>
<style lang="css"></style>
